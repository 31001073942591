import i18n, { t } from 'i18next';
import { Trans } from 'react-i18next';

export const STATUSES = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const AVAILABLE_FILTERS = {
  UNASSIGNED: 'UNASSIGNED',
  REPORTED: 'REPORTED',
  ALL: 'ALL',
  ASSIGNED: 'ASSIGNED',
  LAST_PLANNED: 'LAST_PLANNED',
  COMPLETED: 'COMPLETED',
  TRANSFERRED: 'TRANSFERRED',
  TRANSFERRED_PENDING: 'TRANSFERRED_PENDING',
  TRANSFERRED_UNASSIGNED: 'TRANSFERRED_UNASSIGNED',
  TRANSFERRED_ASSIGNED: 'TRANSFERRED_ASSIGNED',
  TRANSFERRED_COMPLETED: 'TRANSFERRED_COMPLETED',
  CLOSED: 'CLOSED',
};

export const AVAILABLE_FILTER_KEY_TEXT_MAP = {
  [AVAILABLE_FILTERS.UNASSIGNED]: <Trans>UNASSIGNED</Trans>,
  [AVAILABLE_FILTERS.REPORTED]: <Trans>REPORTED</Trans>,
  [AVAILABLE_FILTERS.ALL]: <Trans>ALL</Trans>,
  [AVAILABLE_FILTERS.ASSIGNED]: <Trans>ASSIGNED</Trans>,
  [AVAILABLE_FILTERS.LAST_PLANNED]: <Trans>LAST_PLANNED</Trans>,
  [AVAILABLE_FILTERS.COMPLETED]: <Trans>COMPLETED</Trans>,
  [AVAILABLE_FILTERS.TRANSFERRED]: <Trans>TRANSFERRED</Trans>,
  [AVAILABLE_FILTERS.CLOSED]: <Trans>CLOSED</Trans>,
};

export const ACTION_MODE = {
  OPTIMIZE: 'optimize',
  MANUAL: 'manual',
  QUICK: 'quick',
};

export const TASK_TYPES = {
  pickup: 'pickup',
  dropoff: 'dropoff',
};

export const TASK_TYPE_KEY_TEXT_MAP = {
  [TASK_TYPES.pickup]: <Trans>pickup</Trans>,
  [TASK_TYPES.dropoff]: <Trans>dropoff</Trans>,
};

export const TASK_TYPE_KEY_PLAIN_TEXT_MAP = (t) => ({
  [TASK_TYPES.pickup]: t('pickup'),
  [TASK_TYPES.dropoff]: t('dropoff'),
});

export const AVAILABLE_RIGHT_VIEWS = {
  DRIVERS: 'DRIVERS',
  PARTNERS: 'PARTNERS',
  VEHICLES: 'VEHICLES',
};

export const AVAILABLE_RIGHT_VIEW_KEY_TEXT_MAP = {
  [AVAILABLE_RIGHT_VIEWS.DRIVERS]: <Trans>DRIVERS</Trans>,
  [AVAILABLE_RIGHT_VIEWS.PARTNERS]: <Trans>PARTNERS</Trans>,
  [AVAILABLE_RIGHT_VIEWS.VEHICLES]: <Trans>VEHICLES</Trans>,
};
export const AVAILABLE_VIEWS = {
  STOPS: 'STOPS',
  TASKS: 'TASKS',
  ITEMS: 'ITEMS',
  LOCATION: 'LOCATION',
};

export const AVAILABLE_VIEW_KEY_TEXT_MAP = {
  [AVAILABLE_VIEWS.STOPS]: <Trans>STOPS</Trans>,
  [AVAILABLE_VIEWS.TASKS]: <Trans>TASKS</Trans>,
  [AVAILABLE_VIEWS.ITEMS]: <Trans>ITEMS</Trans>,
  [AVAILABLE_VIEWS.LOCATION]: <Trans>LOCATION</Trans>,
};

export const availableSubStatusFilter = [
  { id: 'missing_info', display_name: 'Missing Info', color: 'red', group: 'unassigned' },
  { id: 'reported', display_name: 'Reported', color: '#3a484e', group: 'unassigned' },
  { id: 'unassigned', display_name: 'Unassigned', color: '#3a484e', group: 'unassigned' },

  { id: 'pending', display_name: 'Pending', color: '#3a484e', group: 'assigned' },
  { id: 'accepted', display_name: 'Accepted', color: '#3a484e', group: 'assigned' },

  { id: 'transferred_pending', display_name: 'Transferred - Pending', color: '#3a484e', group: 'transferred' },
  { id: 'transferred_unassigned', display_name: 'Transferred - Unassigned', color: '#3a484e', group: 'transferred' },
  { id: 'transferred_assigned', display_name: 'Transferred - Assigned', color: '#3a484e', group: 'transferred' },
  { id: 'transferred_reported', display_name: 'Transferred - Reported', color: '#3a484e', group: 'transferred' },
  { id: 'transferred_completed', display_name: 'Transferred - Completed', color: '#3a484e', group: 'transferred' },

  { id: 'cancelled', display_name: 'Cancelled', color: '#3a484e' },
  { id: 'completed', display_name: 'Completed', color: '#3a484e' },
  { id: 'closed', display_name: 'Closed', color: '#3a484e' },
];
export const taskColors = {
  unassigned: { title: 'Unassigned', icon: '', id: 'unassigned', color: '#35ba88' },
  assigned: { title: 'Assigned', icon: '', id: 'assigned', color: '#5f93db' },
  completed: { title: 'Completed', icon: '', id: 'completed', color: '#464646' },
  transferred: { title: 'Transferred', icon: '', id: 'transferred', color: '#9013fe' },
  created: { title: 'Created', icon: '', id: 'created', color: '#5f93db' },
  pending_transfer: { title: 'Transferred - Pending', icon: '', id: 'pending_transfer', color: '#9013fe' },
  default: { title: 'Default', icon: '', id: 'default', color: '#5f93db' },
};

export const hubColors = [
  { title: 'Hubs', icon: '', id: 'hubs', color: '#d7680c' },
  { title: 'Zones', icon: '', id: 'zones', color: '#2f7ae2' },
];

export const SOCKET_EVENTS = {
  LOCATION_UPDATE: 'worker.location_updated',
  ETA_UPDATE: 'worker.eta_updated',
  CHAT_CHANNEL_MESSAGE_SENT: 'worker_channel_message_sent',
  DROP_OFF_COMPLETED: 'dropoff_completed',
  PICKUP_COMPLETED: 'pickup_completed',
  ORDER_ITEM_CANCELED: 'order_item_canceled',
  UNASSIGN_WORKER: 'unassign_worker',
  WORKER_ACCEPTED_TASK_GROUP: 'worker_accepted_task_group',
  WORKER_MARK_AS_FAILED: 'worker_mark_as_failed',
  WORKER_REASSIGNED: 'worker_reassigned',
  SEQUENCE_UPDATED: 'sequence_updated',
  ORDER_CREATED: 'order_created',
};

export const SOCKET_TOPIC = {
  PARTNER_WORKER: 'partner_worker',
};

export const ONE_HOUR_IN_MS = 60 * 60 * 1000;

export const MAX_AMOUNT_OF_SELECTED_TASKS = 5000;

export const BATCH_TEMPLATE_TYPES = {
  SINGLE_TASK: { id: 'single_task', text: t('Single Task'), textKey: 'Single Task' },
  SINGLE_LEG: { id: 'point_to_point', text: t('Single Leg'), textKey: 'Single Leg' },
  MULTIPLE_LEGS: { id: 'multiple_legs', text: t('Multiple Legs'), textKey: 'Multiple Legs' },
};

const translateBatchTemplateTypes = () => {
  Object.keys(BATCH_TEMPLATE_TYPES).forEach((key) => {
    BATCH_TEMPLATE_TYPES[key].text = t(BATCH_TEMPLATE_TYPES[key].textKey);
  });
};

if (i18n.isInitialized) {
  translateBatchTemplateTypes();
}

i18n.on('loaded languageChanged', () => {
  translateBatchTemplateTypes();
});

export const POST_MESSAGE_TYPE = {
  BRANDING_SETTING: 'BRANDING_SETTING',
  SAVED_BRANDING_SETTING: 'SAVED_BRANDING_SETTING',
};

export const DEFAULT_TASK_TYPE_SEQUENCE_SINGLE_LEG = [['pickup', 'dropoff']];

export const ORDER_BOOKING_TAB = {
  charge: { value: 'charge', label: <Trans>Charges</Trans>, index: 1 },
  booking: { value: 'booking', label: <Trans>Booking</Trans>, index: 0 },
  audit_log: { value: 'audit_log', label: <Trans>Audit logs</Trans>, index: 2 },
  documents: { value: 'documents', label: <Trans>Documents</Trans>, index: 3 },
  booking_summary: { value: 'booking_summary', label: <Trans>Summary</Trans>, index: 1 },
  booking_confirmation: { value: 'booking_confirmation', label: <Trans>Confirmation</Trans>, index: 2 },
};

export const FORMAT_DATE_TIME_FOR_NEW_BOOKING = 'yyyy/MM/dd HH:mm';
export const FORMAT_DATE_FOR_NEW_BOOKING = 'yyyy/MM/dd';
export const FORMAT_TIME_FOR_NEW_BOOKING = 'HH:mm';
export const FORMAT_DATE_TIME_NORMAL = 'YYYY-MM-DD HH:mm';
export const FORMAT_DATE_FOR_MOMENT = 'yyyy/MM/DD';
export const FORMAT_DATE_TIME_FOR_MOMENT = 'yyyy/MM/DD HH:mm';
export const FORMAT_DATE_TIME_FOR_LOGS = 'DD MMM YYYY HH:mm';

// Two constants to help format date and/or time according to the ISO 8601
// standard. For example, June 4, 2023 at 4:30 p.m. is represented as
// `2023-06-04 16:30:00.000`.
//
// Ref: https://www.iso.org/iso-8601-date-and-time-format.html
export const FORMAT_DATE_TIME_ISO_8601 = 'YYYY-MM-DD HH:mm:ss.SSS';
export const FORMAT_TIME_ISO_8601 = 'HH:mm:ss.SSS';

export const PACKING_MODE_OPTIONS = {
  ftl: 'FTL - Full Truck Load',
  ltl: 'LTL - Less than Truck Load',
  cnt: 'CNT - Container',
};

export const RATE_CHARGE_TYPES = {
  sell: 'sell',
  buy: 'buy',
  internal_cost: 'internal_cost',
};

export const TIMEZONE_FIELD = 'timezone';

export const SENDER_TYPES = {
  organisation: 'organisation',
};

export const EMPTY_ARRAY = [];

export const TOOLTIP_DISABLED = t('You need permission to perform this action');
export const REQUIRED_FIELD = 'This field is required';
export const OPENING_TIME_SHOULD_BE_LESS_THAN_CLOSING_TIME = (t) => t('Opening time should be less than closing time');
export const CLOSING_TIME_SHOULD_BE_GREATER_THAN_OPENING_TIME = (t) =>
  t('Closing time should be greater than opening time');
export const ADDRESS_TYPE_OPTION = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Hub',
    value: 'Hub',
  },
  {
    label: 'Cross-docking',
    value: 'Cross-docking',
  },
  {
    label: 'Terminal',
    value: 'Terminal',
  },
  {
    label: 'Distribution Center',
    value: 'Distribution Center',
  },
  {
    label: 'Store',
    value: 'Store',
  },
  {
    label: 'Transshipment point',
    value: 'Transshipment point',
  },
  {
    label: 'Railway Station',
    value: 'Railway Station',
  },
  {
    label: 'Residential',
    value: 'Residential',
  },
  {
    label: 'Office',
    value: 'Office',
  },
  {
    label: 'Warehouse',
    value: 'Warehouse',
  },
  {
    label: 'Port',
    value: 'Port',
  },
];

export const DATE_IN_WEEK_OPTION = [
  {
    label: 'Weekdays',
    value: 'Weekdays',
  },
  {
    label: 'Weekend',
    value: 'Weekend',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
];

export const MAX_INPUT_LENGTH = 255;
export const DEBOUNCE_TIME = 300; // In ms

export const DIMENSION_UNITS = [
  { value: 'millimeter', label: 'mm' },
  { value: 'centimeter', label: 'cm' },
  { value: 'foot', label: 'ft' },
  { value: 'meter', label: 'm' },
];

export const WEIGHT_UNITS = [
  { value: 'gram', label: 'g' },
  { value: 'kilogram', label: 'kg' },
  { value: 'pound', label: 'lb' },
];

export const VOLUME_UNITS = [
  { value: 'cubic_centimeter', label: 'cm3' },
  { value: 'cubic_foot', label: 'ft3' },
  { value: 'cubic_meter', label: 'm3' },
];

export const SOLVER_WEIGHT_UNIT = 'g';
export const SOLVER_VOLUME_UNIT = 'cm3';

export const DEBOUNCE_TIME_GOOGLE_API = 1000; // In ms
export const PAIR_TASK_TYPE_POST_FIX = '_paired_task';
